<template>
    <div class="theatre h-100">
        <video-player class="h-100"></video-player>
    </div>
</template>

<script>
import VideoPlayer from "../components/Watch/VideoPlayer";
export default {
    name: "Watch",
    components: {
        VideoPlayer,
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.video-player {

}
</style>
