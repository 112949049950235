<template>
    <div class="player-wrapper position-relative">
        <div class="container sixteen-nine h-100">
            <video ref="videoPlayer"
                   class="content video-js h-100 w-100 mb-3"
                   autoplay>
            </video>
        </div>
    </div>
</template>

<script>
import { apiUrl, apiVersion, } from "../../config";

import localForage from "localforage";

import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";

const playlist = `${ apiUrl }/${ apiVersion }/watch/master.m3u8`;

const options = {
    "autoplay": true,
    "controls": true,
    "controlBar": {
        "children": [
            "playToggle",
            "progressControl",
            "volumePanel",
            "pictureInPictureToggle",
            "fullscreenToggle",
        ],
    },
    "sources": [],
    "html5": {
        "vhs": {
            "withCredentials": true,
            "smoothQualityChange": true,
        }
    },
};

export default {
    name: "VideoPlayer",
    data () {
        return {
            playerInstance: null,
        };
    },
    computed: {

    },
    methods: {

    },
    created () {

    },
    async mounted () {
        const token = await localForage.getItem("ani-singers-live.user-token");
        options.sources.push(
            {
                "src": `${ playlist }`,
                "type": "application/x-mpegURL",
                "withCredentials": true,
            }
        );
        videojs.Vhs.xhr.beforeRequest = function (options) {
            options.headers = options.headers || {};
            options.headers.Authorization = `Bearer ${ token }`;
            return options;
        };
        this.playerInstance = videojs(
            this.$refs.videoPlayer,
            options,
            function onPlayerReady() {
                console.log("onPlayerReady", this);
            }
        );
        this.playerInstance.hlsQualitySelector(
            {
                "displayCurrentQuality": true,
            }
        );
    },
    destroyed () {
        if (this.playerInstance) {
            this.playerInstance.dispose();
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    & > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.player-wrapper {
    min-height: 300px;
    background-color: rgba(0, 0, 0, 1);

    & > .container {
        @media (min-width: 1400px) {
            max-width: 1320px;
        }

        & > video {
            outline: none;
        }
    }

    & > .control-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .playback-control-inputs {
        transition: opacity 250ms ease-in-out;
        opacity: 0;
    }

    &:hover,
    &:focus {
        .playback-control-inputs {
            opacity: 1;
        }
    }
}

.sixteen-nine {
    @include aspect-ratio(16, 9);
}
</style>

<style lang="scss">
.vjs-big-play-button {
    top: 50% !important;
    left: calc(50% - 1.5em) !important;
}
</style>
